import React from 'react';
import { GatsbyLinkProps, PageProps } from 'gatsby';
import { SEOMeta } from 'components/SEO/SEO';
import { EventsData } from 'components/EventsSection/Events';
import _localizeUrl from './localize-url';
import _defaultLocale from './default-locale';

export type Locale = string;
export const DEFAULT_LOCALE: Locale = _defaultLocale;

export const localizeUrl = _localizeUrl as (
  locale: Locale,
  url: string,
) => string;

export function generateLinkToTheNewLocale(
  currentLocale: Locale,
  desiredLocale: Locale,
  location: PageProps['location'],
): GatsbyLinkProps<{}>['to'] {
  // There's no need to do anything if someone is trying to switch to the current locale
  if (currentLocale === desiredLocale) return '#';

  // Stripping currentLocaleCode from current pathname,
  // so we can localize the current pathname for the desiredLocaleCode
  const currentLocationPathnameWithoutLocale =
    location.pathname.replace(`/${currentLocale}`, '') || '/';

  return localizeUrl(desiredLocale, currentLocationPathnameWithoutLocale);
}

export type Translation = {
  id: string;
  translation: { [index: string]: string };
};

export type TranslationResult = {
  [alias: string]: Translation & EventsData & SEOMeta;
};

export function translationsFactory(translations: TranslationResult) {
  return function t(text: string): string {
    return translations?.wpPage?.translation[text] || text;
  };
}

export const TranslationsContext = React.createContext<
  ReturnType<typeof translationsFactory> | undefined
>(undefined);

export function useTranslations() {
  const translations = React.useContext(TranslationsContext);
  if (translations === undefined) {
    throw new Error(
      `useTranslations must be used within the TranslationsContext`,
    );
  }
  return translations;
}

export type Language = { locale: Locale; name: string };
export const LanguagesContext = React.createContext<Language[] | undefined>(
  undefined,
);

export function useLanguages() {
  const languages = React.useContext(LanguagesContext);
  if (languages === undefined) {
    throw new Error(`useLanguages must be used within the LanguagesContext`);
  }
  return languages;
}

export type Letters = {
  translation: {
    letters: string;
  };
};

export const fallbackAlphabet =
  'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z';
