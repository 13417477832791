import React from 'react';
import { PageProps } from 'gatsby';

export const LocationContext = React.createContext<
  PageProps['location'] | undefined
>(undefined);

export function useLocation() {
  const location = React.useContext(LocationContext);

  if (location === undefined) {
    throw new Error(`useLocation must be used within the LocationContext`);
  }
  return location;
}
