import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';

import React from 'react';
import GlobalStyles from './src/styles/global';
import { theme, ThemeProvider } from 'styles/styled';
import { LocationContext } from 'hooks/useLocation';
import { LanguagesContext } from 'settings/i18n';

const addExternalScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.defer = true;
  document.body.appendChild(script);
};

const addInlineScript = (src) => {
  const script = document.createElement('script');
  script.text = src;
  script.defer = true;
  document.body.appendChild(script);
};

export const onRouteUpdate = ({ location }) => {
  if (document.readyState === 'complete') {
    const hash = location.hash.slice(1);
    if (location.hash) {
      setTimeout(() => {
        document.getElementById(hash)?.scrollIntoView(true);
        window.scrollBy(0, -100);
      }, 0);
    }
  }
};

export const onClientEntry = () => {
  window.onload = () => {
    setTimeout(() => {
      addInlineScript(`(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-5JZVHTK');`);
      addInlineScript(`var OneSignal = window.OneSignal || [];
      OneSignal.push(function () {
        OneSignal.init({
          appId: '${process.env.ONE_SIGNAL_APP_ID}',
          notifyButton: {
            enable: true,
            position: 'bottom-left',
          },
          subdomainName: 'miningcity',
        });
      });`);
      addExternalScript(`https://cdn.onesignal.com/sdks/OneSignalSDK.js`);
    }, 100);
  };
};

export const wrapPageElement = ({ element, props }) => (
  <>
    <GlobalStyles />
    <LocationContext.Provider value={props.location}>
      <LanguagesContext.Provider value={props.pageContext.availableLanguages}>
        {element}
      </LanguagesContext.Provider>
    </LocationContext.Provider>
  </>
);

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);

require('@reach/dialog/styles.css');
require('react-pdf/dist/esm/Page/AnnotationLayer.css');
