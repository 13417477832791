// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-403-tsx": () => import("./../../../src/templates/403.tsx" /* webpackChunkName: "component---src-templates-403-tsx" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-education-blockchain-tsx": () => import("./../../../src/templates/education-blockchain.tsx" /* webpackChunkName: "component---src-templates-education-blockchain-tsx" */),
  "component---src-templates-education-cryptocurrency-tsx": () => import("./../../../src/templates/education-cryptocurrency.tsx" /* webpackChunkName: "component---src-templates-education-cryptocurrency-tsx" */),
  "component---src-templates-education-mining-tsx": () => import("./../../../src/templates/education-mining.tsx" /* webpackChunkName: "component---src-templates-education-mining-tsx" */),
  "component---src-templates-education-pdf-preview-tsx": () => import("./../../../src/templates/education-pdf-preview.tsx" /* webpackChunkName: "component---src-templates-education-pdf-preview-tsx" */),
  "component---src-templates-education-quiz-automatic-tsx": () => import("./../../../src/templates/education-quiz-automatic.tsx" /* webpackChunkName: "component---src-templates-education-quiz-automatic-tsx" */),
  "component---src-templates-education-quiz-results-tsx": () => import("./../../../src/templates/education-quiz-results.tsx" /* webpackChunkName: "component---src-templates-education-quiz-results-tsx" */),
  "component---src-templates-education-quiz-tsx": () => import("./../../../src/templates/education-quiz.tsx" /* webpackChunkName: "component---src-templates-education-quiz-tsx" */),
  "component---src-templates-education-tsx": () => import("./../../../src/templates/education.tsx" /* webpackChunkName: "component---src-templates-education-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-maintenance-tsx": () => import("./../../../src/templates/maintenance.tsx" /* webpackChunkName: "component---src-templates-maintenance-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../../../src/templates/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */)
}

