/* eslint-disable @typescript-eslint/no-var-requires */

const DEFAULT_LOCALE = require('./default-locale');

function localizeUrl(locale, url) {
  const result = `/${locale}${url}`.replace(`/${DEFAULT_LOCALE}`, '');

  if (result !== '/' && result.endsWith('/')) {
    return result.slice(0, -1);
  }

  return result;
}

module.exports = localizeUrl;
