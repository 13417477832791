import * as styledComponents from 'styled-components';

type DevicesSize = {
  xsmall: number;
  small: number;
  medium: number;
  large: number;
  xlarge: number;
  xxlarge: number;
};

const deviceSize = {
  xsmall: 350,
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
  xxlarge: 1440,
};

type Devices = {
  xsmall: string;
  small: string;
  medium: string;
  large: string;
  xlarge: string;
  xxlarge: string;
};

const devices = {
  xsmall: `(min-width: ${deviceSize.xsmall}px)`,
  small: `(min-width: ${deviceSize.small}px)`,
  medium: `(min-width: ${deviceSize.medium}px)`,
  large: `(min-width: ${deviceSize.large}px)`,
  xlarge: `(min-width: ${deviceSize.xlarge}px)`,
  xxlarge: `(min-width: ${deviceSize.xxlarge}px)`,
};

type Dimensions = {
  contentMaxWidthBig: number;
  contentMaxWidthMedium: number;
  contentMaxWidthSmall: number;
  spacingBase10: number;
  spacingBase14: number;
  spacingBase16: number;
  basePadding: number;
  menuHeight: number;
  menuWidth: number;
};

const dimensions = {
  contentMaxWidthBig: 1080,
  contentMaxWidthMedium: 912,
  contentMaxWidthSmall: 768,
  spacingBase10: 10,
  spacingBase14: 14,
  spacingBase16: 16,
  basePadding: 24,
  menuHeight: 64,
  menuWidth: 240,
};

type FontSize = {
  labelSmall: string;
  label: string;
  labelBig: string;
  copy: string;
  base: string;
  titleMobile: string;
  title: string;
  pageTitleMobile: string;
  pageTitle: string;
  headingMobile: string;
  heading: string;
  headingMedium: string;
  headingBig: string;
  headingLarge: string;
};

const fontSize = {
  labelSmall: '10px',
  label: '12px',
  labelBig: '14px',
  copy: '15px',
  base: '16px',
  titleMobile: '18px',
  title: '21px',
  pageTitleMobile: '24px',
  pageTitle: '40px',
  headingMobile: '32px',
  headingMedium: '50px',
  heading: '56px',
  headingBig: '64px',
  headingLarge: '160px',
};

type FontWeight = {
  light: number;
  medium: number;
};

const fontWeight = {
  light: 300,
  medium: 500,
};

type Gradient = {
  primary: {
    backgroundImage: string;
    boxShadow: string;
  };
  standard: {
    backgroundImage: string;
    boxShadow: string;
  };
  bronze: {
    backgroundImage: string;
    boxShadow: string;
  };
  silver: {
    backgroundImage: string;
    boxShadow: string;
  };
  gold: {
    backgroundImage: string;
    boxShadow: string;
  };
  platinum: {
    backgroundImage: string;
    boxShadow: string;
  };
  [prop: string]: {
    backgroundImage: string;
    boxShadow: string;
  };
};

const gradient = {
  primary: {
    backgroundImage: `linear-gradient(to bottom, #f4f4f4, #ebebeb)`,
    boxShadow: `0 6px 14px 0 rgba(0, 0, 0, 0.07)`,
  },
  standard: {
    backgroundImage: `linear-gradient(to bottom, #d8efe7, #a6cfc0)`,
    boxShadow: `0 6px 12px 2px rgba(0, 0, 0, 0.08)`,
  },
  bronze: {
    backgroundImage: `linear-gradient(to bottom, #edd0a6, #d4ac79)`,
    boxShadow: `0 6px 14px 0 rgba(0, 0, 0, 0.08)`,
  },
  silver: {
    backgroundImage: `linear-gradient(to bottom, #d1d8de, #b7bfc4)`,
    boxShadow: '0 6px 14px 0 rgba(0, 0, 0, 0.07)',
  },
  gold: {
    backgroundImage: `linear-gradient(to bottom, #fddb7c, #d5b05e)`,
    boxShadow: `0 6px 12px 0 rgba(0, 0, 0, 0.08)`,
  },
  platinum: {
    backgroundImage: `linear-gradient(to bottom, #dcebfd, #bacee6)`,
    boxShadow: `0 6px 12px 2px rgba(0, 0, 0, 0.08)`,
  },
};

type Palette = {
  primary: string;
  primaryLight: string;
  primaryLighter: string;
  primaryDisabled: string;
  primaryHover: string;
  primaryShade1: string;
  primaryInputBorder: string;
  secondary: string;
  secondaryLight: string;
  dark: string;
  darkDisabled: string;
  darkLight: string;
  darkMedium: string;
  darkMedium2: string;
  deepDark: string;
  light: string;
  lightSecondary: string;
  neutral: string;
  neutralLight1: string;
  neutralLight2: string;
  neutralLight3: string;
  neutralLight4: string;
  neutralLight5: string;
  neutralDark: string;
  neutralDark2: string;
  neutralDark3: string;
  neutralDisabled: string;
  neutralMid: string;
  neutralMid2: string;
  success: string;
  error: string;
  warning: string;
  [prop: string]: string;
};

const palette = {
  primary: '#28aaff',
  primaryLight: '#5ec0ff',
  primaryLighter: '#ebf6fe',
  primaryDisabled: '#c9eaff',
  primaryHover: '#66c1fe',
  primaryInputBorder: 'rgba(40, 170, 255, 0.3)',
  primaryShade1: `#74cbff`,

  secondary: '#e5f5ff',
  secondaryLight: '#e9f6ff',

  dark: '#000000',
  darkDisabled: 'rgba(0, 0, 0, 0.2)',
  darkLight: 'rgba(0, 0, 0, 0.08)',
  darkMedium: 'rgba(0, 0, 0, 0.4)',
  darkMedium2: 'rgba(0, 0, 0, 0.5)',
  deepDark: '#020303',

  light: '#ffffff',
  lightSecondary: '#f6f9fb',

  neutral: '#d5d5d5',
  neutralLight1: '#dce2e7',
  neutralLight2: '#f2f4f6', // menu
  neutralLight3: '#edeeef',
  neutralLight4: '#9c9c9c',
  neutralLight5: '#aaaaaa',
  neutralDark: '#333333',
  neutralDark2: '#34495e',
  neutralDark3: '#666666',
  neutralDisabled: 'rgba(216, 216, 216, 0.36)',
  neutralMid: '#b4bbc1',
  neutralMid2: '#b1c6d5',

  success: '#63dbc1',
  error: '#f54b5e',
  warning: '#f8c51c',
};

type UI = {
  border(color?: string): string;
  borderMedium(color?: string): string;
  borderRadius: string;
  boxShadowPaper: string;
  opacity(value?: string): string;
  transition(property: string, multiplier?: number): string;
};

const ui = {
  border: (color = palette.neutral) => `1px solid ${color}`,
  borderMedium: (color = palette.neutral) => `2px solid ${color}`,
  borderRadius: '4px',
  boxShadowPaper: `0 4px 16px 0 rgba(0, 0, 0, 0.09)`,
  transition: (prop: string, multiplier = 1) =>
    `${prop} ${multiplier * 200}ms ease-in`,
  opacity: (value: string) => `${value || 0.3}`,
};

type ZIndexes = {
  header: number;
  menu: number;
  modal: number;
  fullPage: number;
};

const zIndexes = {
  header: 600,
  menu: 500,
  modal: 400,
  fullPage: 900,
};

type LetterSpacings = {
  normal: string;
  tight: string;
};

const letterSpacings = {
  normal: '1.0px',
  tight: '0.7px',
};

export enum PlaceholderVariants {
  Light,
  Medium,
  Dark,
}

export interface ThemeInterface {
  deviceSize: DevicesSize;
  devices: Devices;
  dimensions: Dimensions;
  fontSize: FontSize;
  fontWeight: FontWeight;
  gradient: Gradient;
  palette: Palette;
  ui: UI;
  zIndexes: ZIndexes;
  letterSpacings: LetterSpacings;
}

export const theme: ThemeInterface = {
  deviceSize,
  devices,
  dimensions,
  fontSize,
  fontWeight,
  gradient,
  palette,
  ui,
  zIndexes,
  letterSpacings,
};

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  useTheme,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  ThemeInterface
>;

export { css, createGlobalStyle, keyframes, ThemeProvider, useTheme };
export default styled;
